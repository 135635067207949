import {
    Box,
    Button,
    Text,
    HStack,
    Spacer,
    Checkbox,
    Wrap,
    WrapItem,
    useBoolean,
    Tag,
    TagLabel,
    TagCloseButton,
} from '@chakra-ui/react'
import chunk from 'lodash.chunk'
import PropTypes from 'prop-types'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'

import { highPerformerApi } from '../..'
import { SetIdContext, ToDisplayNameContext } from './HighPerformersContext'
import { ModalForm } from './UserRegistrationForm'

import { Table, TableContainer, Pagination } from '@/components/Elements'
import { Section, SectionTitle } from '@/features/dashboard'

const dashboardColumns = [
    {
        Header: '営業所',
        accessor: 'dispName',
        headerClassName: 'narrow-2',
    },
    {
        Header: '評価者',
        accessor: 'hp',
        Cell: Users,
    },
    {
        Header: '',
        accessor: '_id',
        headerClassName: 'button',
        cellClassName: 'center',
        Cell: RegisterButton,
    },
]
//AI評価
// export const HighPerformers = () => {
//     const [isChecked, setIsChecked] = useState(false)
//     const ref = useRef(null)
//     return (
//         <Section ref={ref} scrollMarginTop="var(--header-height)">
//             <HStack className="title-container">
//                 <SectionTitle>AI評価</SectionTitle>
//                 <Spacer />
//                 <Checkbox
//                     isChecked={isChecked}
//                     onChange={(e) => {
//                         setIsChecked(e.target.checked)
//                         ref?.current?.scrollToStart()
//                     }}
//                 >
//                     全て表示
//                 </Checkbox>
//             </HStack>
//             <HighPerformerList allData={isChecked} />
//             {isChecked && (
//                 <Button
//                     onClick={() => {
//                         ref?.current?.scrollToStart()
//                     }}
//                 >
//                     上部に戻る
//                 </Button>
//             )}
//         </Section>
//     )
// }

// NOTE: 今のところチェックボックスを切り替えたときにページングはリセットされない
const HighPerformerList = ({ allData }) => {
    const [id, setId] = useState(null)
    const [page, setPage] = useState(1)
    const { isLoading, data } =
        highPerformerApi.endpoints.listHighPerformers.useQuery()
    const items = useMemo(() => {
        if (!data?.items) {
            return []
        }
        return allData ? data.items : chunk(data.items, 5)
    }, [allData, data])
    const toDisplayName = useCallback(
        (val) => {
            let displayName = ''
            if (Array.isArray(data?.items)) {
                for (let index = 0; index < data.items.length; index++) {
                    const item = data.items[index]
                    if (val === item.name) {
                        displayName = item.dispName
                        break
                    }
                }
            }
            return displayName
        },
        [data]
    )
    if (isLoading) {
        return <Text>Loading...</Text>
    }
    return (
        <SetIdContext.Provider value={setId}>
            <TableContainer layerStyle="dataTable">
                <Table
                    data={allData ? items : items[page - 1]}
                    columns={dashboardColumns}
                />
            </TableContainer>
            <ToDisplayNameContext.Provider value={toDisplayName}>
                <ModalForm id={id} />
            </ToDisplayNameContext.Provider>
            {!allData && (
                <Box className="pagination-container">
                    <Pagination
                        totalCount={items.length}
                        currentPage={page}
                        handlePageChanged={setPage}
                    />
                </Box>
            )}
        </SetIdContext.Provider>
    )
}
HighPerformerList.propTypes = {
    allData: PropTypes.bool.isRequired,
}

function Users(props) {
    const { value, row } = props
    return (
        <Wrap
            sx={{
                'li+li::before': {
                    content: '"／"',
                    mr: 'var(--wrap-x-spacing)',
                },
            }}
        >
            {value.map((user) => (
                <WrapItem key={user}>
                    <UserTag userId={user} officeId={row.original._id} />
                </WrapItem>
            ))}
        </Wrap>
    )
}
Users.propTypes = {
    value: PropTypes.array.isRequired,
    row: PropTypes.any.isRequired,
}

const UserTag = ({ userId, officeId }) => {
    const [isHover, { on, off }] = useBoolean()
    const [removeHighPerformer, { isLoading }] =
        highPerformerApi.endpoints.removeHighPerformer.useMutation()
    return (
        <Box onMouseEnter={on} onMouseLeave={off}>
            <Text as="span" display={isHover ? 'none' : undefined}>
                {userId}
            </Text>
            <Tag display={isHover ? undefined : 'none'}>
                <TagLabel>{userId}</TagLabel>
                <TagCloseButton
                    isDisabled={isLoading}
                    onClick={() => {
                        removeHighPerformer({
                            _id: officeId,
                            userId,
                        })
                    }}
                />
            </Tag>
        </Box>
    )
}
UserTag.propTypes = {
    userId: PropTypes.string.isRequired,
    officeId: PropTypes.string.isRequired,
}

function RegisterButton(props) {
    const setId = useContext(SetIdContext)
    const { value } = props
    // console.log(props)
    return (
        <Button
            variant="link"
            onClick={() => {
                setId(value)
            }}
        >
            登録
        </Button>
    )
}
RegisterButton.propTypes = {
    value: PropTypes.string.isRequired,
}
