import { InfoOutlineIcon, RepeatIcon } from '@chakra-ui/icons'
import {
    Text,
    Box,
    Image,
    Menu,
    MenuButton,
    MenuList,
    VStack,
    HStack,
    useDisclosure,
    forwardRef,
    Divider,
    chakra,
    Button,
    ButtonGroup,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useContext, useMemo, useState } from 'react'
// import { IoChatbubblesOutline } from 'react-icons/io5'
import { useAuth } from 'react-oidc-context'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ExternalLink, Link } from '../Elements'
import { MenuListButton } from './MainLayout/Header/Header'

import {
    ToggleViewingModeDispatchContext,
    ToggleViewingModeStateContext,
} from '.'

import { LearnIcon, HomeIcon, AdminIcon } from '@/assets/icon'
import Logo from '@/assets/img/TRYT_colour_posi.png'
import { GIT_VERSION } from '@/config'
// import { NODE_BB_BASE_URL } from '@/config'
import { useAdmin } from '@/features/auth'
import { NewContactButton } from '@/features/contact'
import { FacilityContext } from '@/features/facilities/components/FacilityDetail/FacilityItems'
import { HighPerformersOnly } from '@/features/high-performers'
import { notificationsSelectors } from '@/stores'

const Alert = () => {
    const notifications = useSelector(notificationsSelectors.selectAll)
    const unread = useMemo(
        () => notifications.some(({ read }) => read === false),
        [notifications]
    )
    if (unread) {
        return (
            <Text>
                <InfoOutlineIcon />
                &nbsp; 新しい通知があります
            </Text>
        )
    }
    return null
}

// see: https://chakra-ui.com/docs/components/menu/usage#customizing-the-button
export const LogoLink = forwardRef(function LogoLink({ home }, ref) {
    return (
        <Box
            ref={ref}
            // HACK: メニューの隙間でマウスイベントが派生しないように埋める
            mb={2}
        >
            <Link to={home}>
                <Image
                    alt="ロゴ"
                    w="200px"
                    minW="200px"
                    h="45.74px"
                    src={Logo}
                />
            </Link>
            {/* <Alert /> */}
        </Box>
    )
})

const LogoutButton = () => {
    const { isAuthenticated } = useAuth()
    const navigate = useNavigate()
    return (
        isAuthenticated && (
            <MenuListButton
                onClick={() => {
                    navigate('/logout')
                }}
            >
                ログアウト
            </MenuListButton>
        )
    )
}

const ToggleJobseekerViewButton = () => {
    const dispatch = useContext(ToggleViewingModeDispatchContext)
    const { isJobseekerViewing } = useContext(ToggleViewingModeStateContext)

    return (
        <Button
            // className="logo-button"
            // leftIcon={<RepeatIcon />}
            color="black"
            _active={{ color: 'black' }}
            leftIcon={<RepeatIcon w="16px" h="16px" mr="4px" />}
            onClick={() => {
                dispatch({ type: 'TOGGLE_VIEWING_MODE' })
            }}
        >
            {isJobseekerViewing ? '内部利用モード' : '候補者モード'}
        </Button>
    )
}

const AppVersion = () => <Text>バージョン：{GIT_VERSION}</Text>

export const LogoMenu = ({ home = '/', isDisplay = true }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const isAdminPage = AdminPageChecker()
    return (
        <Box onMouseEnter={onOpen} onMouseLeave={onClose} pt="8px">
            <Menu isOpen={isOpen}>
                {/* NOTE: MenuButtonの子要素にすると「pointerEvents: "none"」になってしまうので直接置き換える */}
                <MenuButton as={LogoLink} home={home} />
                {/* visibility: hidden で描画される */}

                {/* ロゴメニューからハンバーガーメニューに変更 */}
                {/* <MenuList>
                    // <VStack px={2}>
                    //     <HighPerformersOnly>
                    //         <RecommendationEvaluationButton />
                    //     </HighPerformersOnly>
                    //     <TaskChecker />
                    //     <RevisionChecker />

                    //     {path === '/contacts' ? (
                    //         <NewContactButton
                    //             close
                    //             isHeader
                    //             backURL={location.href}
                    //         />
                    //     ) : (
                    //         <ContactLink />
                    //     )}
                        <ButtonGroup
                            variant="link"
                            as={VStack}
                            spacing={0}
                            w="fit-content"
                            alignItems="flex-start"
                            sx={{
                                '.logo-button': {
                                    svg: {
                                        w: 5,
                                        h: 5,
                                    },
                                    color: 'black',
                                    '&:active': {
                                        color: 'black',
                                    },
                                },
                                // a: {
                                //     svg: {
                                //         w: 5,
                                //         h: 5,
                                //     },
                                //     color: 'black',
                                //     '&:active': {
                                //         color: 'black',
                                //     },
                                // },
                            }}
                        >
                            {isDisplay && (
                                <>
                                    <ContactButton />
                                    // <BulletinBoardButton />
                                    <EvaluationButton />
                                    <NewdevelopmentButton />
                                    <AdminButton />
                                    <FrontButton />
                                    {!isAdminPage && (
                                        <ToggleJobseekerViewButton />
                                    )}
                                </>
                            )}
                        </ButtonGroup>
                        <Divider />

                        <LogoutButton />
                        <AppVersion />
                    // </VStack>
                </MenuList> */}
            </Menu>
        </Box>
    )
}

LogoMenu.propTypes = {
    home: PropTypes.string,
    isDisplay: PropTypes.bool,
}

const Notification = ({ children, isNew = false }) => {
    return (
        <HStack w="full" pl={2} pr={3}>
            <chakra.div
                bg="blue.500"
                borderRadius="50%"
                height="8px"
                width="8px"
                visibility={isNew ? 'inherit' : 'hidden'}
            />
            {children}
        </HStack>
    )
}
Notification.propTypes = {
    children: PropTypes.node.isRequired,
    isNew: PropTypes.bool,
}

// TRYTDX-748
export const TaskChecker = () => {
    const notification = useSelector((state) =>
        notificationsSelectors.selectById(state, 'similar-facility-tasks')
    )
    return (
        <Notification isNew={notification?.read === false}>
            <Link to="/TRYTDX837">
                レコメンド評価（{notification?.data?.count ?? 0}）
            </Link>
        </Notification>
    )
}

// TODO: ここからアプリのバージョンアップをしたい
export const RevisionChecker = () => {
    return (
        <Notification>
            <Text>ダミーの通知（複数行確認用）</Text>
        </Notification>
    )
}

const ContactButton = () => {
    return (
        <Button className="logo-button">
            <NewContactButton backURL={location.href} isHeader />
        </Button>
    )
}

// const BulletinBoardButton = () => {
//     const openWindow = () => {
//         const w = (screen.availWidth - 1000) / 2
//         const h = (screen.availHeight - 700) / 2
//         const url = `${NODE_BB_BASE_URL}`

//         const newWindow = window.open(
//             url,
//             null,
//             `width=1000,height=700,left=${w},top=${h}`
//         )
//     }

//     return (
//         <Button
//             onClick={openWindow}
//             leftIcon={<IoChatbubblesOutline />}
//             className="logo-button"
//         >
//             要望・ご意見用掲示板
//         </Button>
//     )
// }

//AI評価
// const EvaluationButton = () => {
//     return (
//         <HighPerformersOnly>
//             <Button
//                 className="logo-button"
//                 variant="link"
//                 as={ExternalLink}
//                 href="/evaluations"
//                 leftIcon={<LearnIcon />}
//             >
//                 AI評価
//             </Button>
//         </HighPerformersOnly>
//     )
// }

const NewDevlopmentPageChecker = () => {
    return location.pathname.startsWith('/new-development') ? true : false
}

const NewdevelopmentButton = () => {
    const isNewDevlopmetPage = NewDevlopmentPageChecker()
    return (
        !isNewDevlopmetPage && (
            <Button
                className="logo-button"
                variant="link"
                as={ExternalLink}
                href="/new-development"
                leftIcon={<LearnIcon />}
            >
                新規開拓
            </Button>
        )
    )
}

const AdminButton = () => {
    const navigate = useNavigate()
    const isAdminPage = AdminPageChecker()
    const { isAdmin } = useAdmin()
    return (
        isAdmin &&
        !isAdminPage && (
            <Button
                className="logo-button"
                leftIcon={<AdminIcon />}
                onClick={() => navigate('/admin')}
            >
                管理画面
            </Button>
        )
    )
}

const AdminPageChecker = () => {
    return location.pathname.startsWith('/admin') ? true : false
}

const FrontButton = () => {
    const navigate = useNavigate()
    const isAdminPage = AdminPageChecker()
    return (
        isAdminPage && (
            <Button
                className="logo-button"
                leftIcon={<HomeIcon />}
                onClick={() => {
                    navigate('/')
                }}
            >
                ユーザー画面
            </Button>
        )
    )
}
