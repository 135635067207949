import { timeRegExp } from '../../utils/workingHour/index.js'
import { joi } from '../joi.js'

const { string: _string, number: _number } = joi.types()
const string = _string.empty([null, '']).default(null)
const number = _number.empty([null, '']).default(null).integer().min(1)
const numberfield = _number.empty([null, '']).default(null)
const stringWithLabel = string.alter({ client: (schema) => schema.value() })
const numberWithLabel = number.alter({ client: (schema) => schema.value() })
const numberFieldWithLabel = numberfield.alter({
    client: (schema) => schema.value(),
})

// TODO: サーバー側のバリデーション
export const searchFormValidator = joi
    .object({
        requiredTime: joi
            //#region
            .object({
                departure: string.valid('address', 'station').label('出発地点'),
                type: numberWithLabel
                    //#region
                    .valid(1, 2, 3, 4)
                    .label('通勤手段'),
                //#endregion
                address: joi
                    //#region
                    .object({
                        postCode: numberWithLabel.label('郵便番号'),
                        prefecture: stringWithLabel.label('都道府県'),
                        city: stringWithLabel.label('市区町村'),
                        street: string.label('町域・番地'),
                    })
                    .label('住所'),
                //#endregion
                location: joi.object({
                    lat: joi.number().required(),
                    lon: joi.number().required(),
                    isAmbiguous: joi.boolean(),
                }),
                station: joi
                    //#region
                    .array()
                    .items(
                        joi
                            .object({
                                id: joi.any(),
                                type: joi.number().valid(1, 2),
                                // label: joi.any(),
                            })
                            .options({ stripUnknown: true })
                    )
                    .messages({
                        'array.min': '最寄駅を1つ以上選択してください',
                    })
                    .label('最寄駅'),
                //#endregion
                time: numberWithLabel
                    //#region
                    .integer()
                    .label('移動時間'),
                //#endregion
            })
            .alter({
                client: (schema) => {
                    return schema
                        .keys({
                            switch: joi.bool().strip(),
                        })
                        .when('.switch', {
                            is: true,
                            then: joi
                                //#region
                                .object({
                                    departure: joi.required(),
                                    type: joi.required(),
                                    time: joi.required(),
                                    address: joi.when('departure', {
                                        is: 'address',
                                        then: joi.object({
                                            prefecture: joi.required(),
                                            city: joi.required(),
                                            street: joi.any().empty([null, '']),
                                        }),
                                    }),
                                    station: joi.when('type', {
                                        is: 4,
                                        then: joi.array().min(1).required(),
                                    }),
                                }),
                            //#endregion
                            otherwise: joi.object().strip(),
                        })

                    // .strip()
                },
            }),
        //#endregion
        address: joi
            .array()
            .items(
                joi.object({
                    pref: joi.string().required(),
                    city: joi.string().empty('').default(''),
                })
            )
            .single(),
        station: joi
            .array()
            .items(
                joi
                    .object({
                        id: joi.any(),
                        type: joi.number().valid(1, 2),
                        label: joi.any(),
                    })
                    .options({ stripUnknown: true })
            )
            .label('沿線絞り込み'),
        serviceCode: joi
            .array()
            .items(stringWithLabel)
            .single()
            .label('施設形態'),
        lastTaskConfirmDate: numberFieldWithLabel.label('業務確認の有無'),
        contract: joi.array().items(stringWithLabel).single().label('手数料率'),
        feePercent: numberWithLabel.label('手数料率'),
        acutualSalariesPerQualifications:
            numberFieldWithLabel.label('紹介実績の有無'),
        outsourceflg: joi
            .array()
            .items(stringWithLabel)
            .single()
            .label('取引先区分'),
        closedOn: joi
            .array()
            .items(stringWithLabel)
            .single()
            .label('施設定休日'),
        numOfUsers: joi
            .array()
            .items(stringWithLabel.pattern(/^\d*-\d*$/))
            .single()
            .label('施設規模'),
        clinicalDepartment: joi
            .array()
            .items(stringWithLabel)
            .single()
            .label('診療科目'),
        openingRate: joi
            .array()
            .items(numberFieldWithLabel)
            .single()
            .label('求人発生率'),
        offer: joi.object({
            qualification: joi
                .array()
                .items(stringWithLabel)
                .single()
                .label('保有資格'),
            workShift: joi
                .array()
                .items(stringWithLabel)
                .single()
                .label('勤務形態'),
            employmentType: joi
                .array()
                .items(stringWithLabel)
                .single()
                .label('雇用形態'),
            layer: joi
                .array()
                .items(stringWithLabel)
                .single()
                .label('ポジション'),
            income: {
                annual: numberWithLabel.label('年収'),
                monthly: numberWithLabel.label('月給'),
                daily: numberWithLabel.label('日給'),
                hourly: numberWithLabel.label('時給'),
            },
            offerType: stringWithLabel.label('求人の種類'),
            status: stringWithLabel.label('求人ステータス'),
            updatedDate: numberWithLabel.label('更新日'),
            workingHours: {
                from: string
                    .pattern(timeRegExp)
                    .message('HH時MM分の形式で入力してください')
                    .label('勤務開始時刻'),
                to: string
                    .pattern(timeRegExp)
                    .message('HH時MM分の形式で入力してください')
                    .label('勤務終了時刻'),
            },
            mode: joi.string(),
        }),
        record: {
            datewithin: numberWithLabel.label('計上日'),
            ageGroup: joi
                .array()
                .items(stringWithLabel.pattern(/^\d*-\d*$/))
                .label('年齢'),
            qualification: joi
                .array()
                .items(stringWithLabel)
                .single()
                .label('保有資格'),
            workShift: joi
                .array()
                .items(stringWithLabel)
                .single()
                .label('勤務形態'),
            income: {
                annual: numberWithLabel.label('年収'),
                monthly: numberWithLabel.label('月給'),
                hourly: numberWithLabel.label('時給'),
            },
        },
    })
    .alter({
        client: (schema) =>
            schema.keys({
                sort: joi
                    .string()
                    .empty('')
                    .default((parent) => {
                        return parent?.sortType?.sort
                    }),
                direction: joi
                    .string()
                    .empty(null)
                    .default((parent) => {
                        return parent?.sortType?.direction
                    }),
                sortType: joi.object().empty(null).strip(),
            }),
    })

export const stationFormValidator = joi.object({
    keyword: {
        prefecture: joi.any().required().label('都道府県'),
        q: {
            name: joi.string().empty('').required().label('駅名'),
        },
    },
})

export const zipcodeValidator = joi
    .string()
    .min(7)
    .pattern(/^[0-9]{7}$/)
    .required()
    .error(new Error('数字7文字で入力してください'))
