import { APIProvider } from '@vis.gl/react-google-maps'
import PropTypes from 'prop-types'
import { useState } from 'react'

import { GOOGLE_MAPS_API_KEY_CLIENT } from '@/config'

/** @deprecated MapsJavascriptAPIを複数回読み込むとエラーが出るので機能削除。↓に移行 */
export const MapContainer = ({ children }) => {
    return <>{children}</>
}
MapContainer.propTypes = {
    children: PropTypes.node.isRequired,
}

const defaultLibraries = ['marker']
export const GoogleMapsAPIProvider = ({ children }) => {
    // see: https://visgl.github.io/react-google-maps/docs/api-reference/components/api-provider
    const [isLoaded, setIsLoaded] = useState(false)
    return (
        <APIProvider
            apiKey={GOOGLE_MAPS_API_KEY_CLIENT}
            libraries={defaultLibraries}
            region="JP"
            language="ja"
            onLoad={() => {
                setIsLoaded(true)
            }}
        >
            {isLoaded && children}
        </APIProvider>
    )
}
GoogleMapsAPIProvider.propTypes = {
    children: PropTypes.node.isRequired,
}
