import { createContext, useContext } from 'react'

/** @typedef {ReturnType<typeof import('react').useRef<HTMLDivElement>>} Reference */

/** @typedef {google.maps.Map} Map */

/** @type {ReturnType<typeof createContext<{ ref: Reference, instance: Map }>>} */
const MapContext = createContext({ ref: null, instance: null })

export const MapProvider = MapContext.Provider

export const useMapContext = () => useContext(MapContext)
