import '@fontsource/noto-sans-jp/400.css'
import '@fontsource/noto-sans-jp/700.css'

import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { HelmetProvider } from 'react-helmet-async'
import { AuthProvider } from 'react-oidc-context'
// import { BrowserRouter as Router } from 'react-router-dom'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'

import { ErrorDetection } from './ErrorDetection'

import { appTheme } from '@/assets/styles/theme'
import { ErrorLayout } from '@/components/Layout'
import { GoogleMapsAPIProvider } from '@/components/Maps'
import {
    OIDC_AUTHORITY,
    OIDC_CLIENT_ID,
    OIDC_CLIENT_SECRET,
    OIDC_REDIRECT_URI,
} from '@/config'
import { Page500 } from '@/features/misc'
import { history } from '@/lib/history'
import { store } from '@/store'

const Loading = () => <p>Loading...</p>

// see: https://github.com/authts/oidc-client-ts/blob/main/src/OidcClientSettings.ts
const authConfig = {
    authority: OIDC_AUTHORITY,
    client_id: OIDC_CLIENT_ID,
    client_secret: OIDC_CLIENT_SECRET,
    redirect_uri: OIDC_REDIRECT_URI,
    onRemoveUser: () => {
        // reset redux state
        store.dispatch({ type: 'store/reset' })
    },
    loadUserInfo: true,
}

const theme = extendTheme(appTheme)

const AppProvider = ({ children }) => {
    return (
        <Suspense fallback={<Loading />}>
            <ErrorBoundary FallbackComponent={Page500}>
                <HelmetProvider>
                    <AuthProvider {...authConfig}>
                        <ChakraProvider theme={theme}>
                            {/* <Router>{children}</Router> */}
                            <HistoryRouter history={history}>
                                <ErrorBoundary FallbackComponent={ErrorLayout}>
                                    <ErrorDetection>
                                        <GoogleMapsAPIProvider>
                                            {children}
                                        </GoogleMapsAPIProvider>
                                    </ErrorDetection>
                                </ErrorBoundary>
                            </HistoryRouter>
                        </ChakraProvider>
                    </AuthProvider>
                </HelmetProvider>
            </ErrorBoundary>
        </Suspense>
    )
}

AppProvider.propTypes = {
    children: PropTypes.node,
}

export { AppProvider }
