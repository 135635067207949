import { Box, Spacer } from '@chakra-ui/react'
import { set, addMinutes } from 'date-fns'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { joi } from 'shared'

import {
    useCreateMaintenanceMutation,
    useEditMaintenanceMutation,
} from '../api'
import { NotificationForm } from './NotificationForm'

/**
 * @typedef {{
 *     _id: string
 *     period: { begin: Date; end: Date }
 *     status: number
 * }} Notification
 */
/** @typedef {{ content: string; url?: string } & Notification} Announcement */
/** @typedef {{ duration: { begin: Date; end: Date } } & Notification} Maintenance */

/**
 * @type {React.FC<{
 *     onClose?: any
 *     initMaintenance: Maintenance | null
 * }>}
 */
export const CreateMaintenanceContainer = ({ onClose, initMaintenance }) => {
    const [triggerCreate] = useCreateMaintenanceMutation()
    const [triggerEdit] = useEditMaintenanceMutation()

    // 編集モードの場合、初期データを設定する
    const initialFormData = useMemo(() => {
        if (!initMaintenance) {
            return null
        }

        const begin = new Date(initMaintenance.duration.begin)
        // yyyy-mm-dd形式の文字列に変換する, toISOStringは標準時間で返すので日本時間に変換する
        const dateStr = set(begin, {
            hours: begin.getHours() + 9,
        }).toISOString()

        return {
            // date,timeはISOStringで取得した後日本時間に変換する
            date: dateStr.slice(0, 10),
            time: dateStr.slice(11, 16),
            // minutesはduration.end - duration.beginで計算する
            minutes:
                // @ts-ignore
                (initMaintenance.duration.end -
                    // @ts-ignore
                    initMaintenance.duration.begin) /
                (1000 * 60),
            hidden: initMaintenance.status === 2,
        }
    }, [initMaintenance])

    const today = new Date(
        new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })
    )

    const validator = useMemo(
        () =>
            joi.object({
                date: joi
                    .date()
                    .required()
                    .min(today.setHours(0, 0, 0, 0))
                    .message('今日以降の日付を入力してください')
                    .label('日付'),
                time: joi
                    .string()
                    .regex(/^([01][0-9]|2[0-3]):([0-5][0-9])$/)
                    .required()
                    .custom((value, helpers) => {
                        //入力された日付の取得
                        const date = helpers.state.ancestors[0].date

                        //入力された日時と現在の日時を比較
                        const inputDate = new Date(date)
                        const [hours, minutes] = value
                            .split(':')
                            .map((str) => Number(str))
                        inputDate.setHours(hours, minutes, 0, 0)

                        const now = new Date(
                            new Date().toLocaleString('ja-JP', {
                                timeZone: 'Asia/Tokyo',
                            })
                        )

                        if (inputDate < now) {
                            return helpers.message(
                                '現在時刻以降を指定してください'
                            )
                        }
                        return value
                    })
                    .label('開始時刻'),
                minutes: joi
                    .number()
                    .integer()
                    .required()
                    .min(1)
                    .label('適用時間'),
                content: joi.string().default(''),
                hidden: joi.bool().required(),
            }),
        []
    )

    const onSubmit = useCallback(async (data) => {
        // eslint-disable-next-line no-console
        console.info('[submit]', { data }) // for develop
        const [hours, minutes] = data.time.split(':').map((str) => Number(str))
        const begin = set(data.date, { hours, minutes })
        const end = addMinutes(begin, data.minutes)
        const maintenance = {
            period: { begin: new Date(), end },
            duration: { begin, end },
            status: data.hidden ? 2 : 1,
        }
        // eslint-disable-next-line no-console
        console.log('[send request]', { data: maintenance }) // for develop
        if (initMaintenance) {
            await triggerEdit({
                id: initMaintenance._id,
                maintenance: maintenance,
            })
                .unwrap()
                .then(() => {
                    alert('編集成功しました。')
                    onClose()
                })
        } else {
            await triggerCreate(maintenance)
                .unwrap()
                .then(() => {
                    alert('作成成功しました。')
                    onClose()
                })
        }
    }, [])
    return (
        <CreateMaintenance
            onSubmit={onSubmit}
            validator={validator}
            onClose={onClose}
            initFormData={initialFormData}
        />
    )
}

CreateMaintenanceContainer.propTypes = {
    onClose: PropTypes.func,
    initMaintenance: PropTypes.any,
}

/**
 * @type {React.FC<{
 *     onSubmit: (data: object) => void | Promise<void>
 *     validator?: any
 *     onClose: (data: object) => void
 *     initFormData: {
 *         date: string
 *         time: string
 *         minutes: number
 *         hidden: boolean
 *     } | null
 * }>}
 */
const CreateMaintenance = ({ onSubmit, validator, onClose, initFormData }) => {
    return (
        <NotificationForm
            createOnSubmit={({ reset }) =>
                async (data) => {
                    await onSubmit(data)
                    reset()
                }}
            validator={validator}
            defaultValues={initFormData}
        >
            <NotificationForm.Control isRequired>
                <NotificationForm.Label>日付</NotificationForm.Label>
                <NotificationForm.DateInput
                    name="date"
                    type="date"
                    width="auto"
                />
            </NotificationForm.Control>
            <Box width="80%">
                <NotificationForm.Control isRequired>
                    <NotificationForm.Label>時間帯</NotificationForm.Label>
                    <NotificationForm.Input name="time" type="time" />
                    <Spacer marginRight="8px" />
                    ～
                    <Spacer marginRight="8px" />
                    <NotificationForm.Input
                        name="minutes"
                        type="number"
                        right="分"
                    />
                </NotificationForm.Control>
            </Box>
            <Box display="flex">
                <NotificationForm.CheckBox name="hidden">
                    非表示
                </NotificationForm.CheckBox>

                <Spacer />
                <NotificationForm.Button
                    type="button"
                    onClick={onClose}
                    marginLeft="8px"
                >
                    キャンセル
                </NotificationForm.Button>
                <NotificationForm.Button type="submit" marginLeft="8px">
                    OK
                </NotificationForm.Button>
            </Box>
        </NotificationForm>
    )
}

CreateMaintenance.propTypes = {
    onSubmit: PropTypes.func,
    validator: PropTypes.any,
    onClose: PropTypes.func,
    initFormData: PropTypes.any,
}
