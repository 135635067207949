/* eslint-disable react/jsx-key */
import {
    Table as ChakraTable,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Popover,
    useDisclosure,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { cloneElement } from 'react'
import { useTable } from 'react-table'

/**
 * @see {@link https://chakra-ui.com/getting-started/with-react-table}
 * @see {@link https://github.com/TanStack/table/blob/v7/docs/src/pages/docs/examples/data-driven-classes-and-styles.md}
 * @see {@link https://react-table-v7-docs.netlify.app/} 上リンク切れのため
 */
const Table = ({ columns, data, caption, isPopoverEnabled = false }) => {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
    })

    // Render the UI for your table
    return (
        <ChakraTable {...getTableProps()}>
            {caption && <TableCaption>{caption}</TableCaption>}
            <Thead>
                {headerGroups.map((headerGroup) => {
                    return (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => {
                                return (
                                    <Th
                                        {...column.getHeaderProps([
                                            {
                                                className:
                                                    column['headerClassName'],
                                            },
                                        ])}
                                    >
                                        {column.render('Header')}
                                    </Th>
                                )
                            })}
                        </Tr>
                    )
                })}
            </Thead>
            <Tbody>
                {rows.map((row) => {
                    prepareRow(row)
                    // console.log(row)
                    return (
                        <PopoverWrapper
                            key={row.id}
                            isPopoverEnabled={isPopoverEnabled}
                        >
                            <Tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <Td
                                            {...cell.getCellProps([
                                                {
                                                    className:
                                                        cell.column[
                                                            'cellClassName'
                                                        ],
                                                },
                                            ])}
                                        >
                                            {cell.render('Cell')}
                                        </Td>
                                    )
                                })}
                            </Tr>
                        </PopoverWrapper>
                    )
                })}
            </Tbody>
        </ChakraTable>
    )
}
Table.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    caption: PropTypes.string,
    getRowProps: PropTypes.func,
    isPopoverEnabled: PropTypes.bool,
}

/** @see {@link https://chakra-ui.com/docs/components/popover/usage#controlled-usage} */
const PopoverWrapper = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    if (props.isPopoverEnabled) {
        return (
            <Popover
                isOpen={isOpen}
                trigger="hover"
                placement="right"
                isLazy
                lazyBehavior="keepMounted"
                modifiers={[
                    {
                        name: 'preventOverflow',
                        options: {
                            padding: 120,
                        },
                    },
                ]}
            >
                {cloneElement(props.children, {
                    _hover: { bg: '#f6f6f6' },
                    onMouseEnter: onOpen,
                    onMouseLeave: onClose,
                })}
            </Popover>
        )
    }
    return props.children
}
PopoverWrapper.propTypes = {
    isPopoverEnabled: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
}

export { Table, TableContainer }
