import { InfoOutlineIcon } from '@chakra-ui/icons'
import {
    Text,
    Box,
    VStack,
    HStack,
    Divider,
    chakra,
    Button,
    ButtonGroup,
    Switch,
    FormControl,
    FormLabel,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { useAuth } from 'react-oidc-context'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
    ToggleViewingModeDispatchContext,
    ToggleViewingModeStateContext,
} from '../MainLayout'
import { MenuListButton } from './Header'

import { LearnIcon, HomeIcon, AdminIcon } from '@/assets/icon'
import { ExternalLink, Link } from '@/components/Elements'
import { GIT_VERSION } from '@/config'
import { useAdmin } from '@/features/auth'
import { NewContactButton } from '@/features/contact'
import { HighPerformersOnly } from '@/features/high-performers'
import { notificationsSelectors } from '@/stores'

const Alert = () => {
    const notifications = useSelector(notificationsSelectors.selectAll)
    const unread = useMemo(
        () => notifications.some(({ read }) => read === false),
        [notifications]
    )
    if (unread) {
        return (
            <Text>
                <InfoOutlineIcon />
                &nbsp; 新しい通知があります
            </Text>
        )
    }
    return null
}

const LogoutButton = () => {
    const { isAuthenticated } = useAuth()
    const navigate = useNavigate()
    return (
        isAuthenticated && (
            <MenuListButton
                onClick={() => {
                    navigate('/logout')
                }}
            >
                ログアウト
            </MenuListButton>
        )
    )
}

const ToggleJobseekerViewButton = () => {
    const dispatch = useContext(ToggleViewingModeDispatchContext)

    return (
        <FormControl display="flex" alignItems="center">
            <FormLabel mb="0" color="tryt.blue-1" textDecoration="underline">
                候補者モード
            </FormLabel>
            <Switch
                onChange={() => {
                    dispatch({ type: 'TOGGLE_VIEWING_MODE' })
                }}
            />
        </FormControl>
    )
}

const AppVersion = () => <Text>バージョン：{GIT_VERSION}</Text>

export const HamburgerMenu = ({ onClose, isDisplay = true }) => {
    const isAdminPage = AdminPageChecker()
    return (
        <Box>
            {/* visibility: hidden で描画される */}
            <VStack px={2}>
                {isDisplay && (
                    <ButtonGroup
                        variant="link"
                        as={VStack}
                        spacing={0}
                        w="fit-content"
                        alignItems="flex-start"
                        sx={{
                            '.menu-button': {
                                svg: {
                                    w: 5,
                                    h: 5,
                                },
                                color: 'black',
                                '&:active': {
                                    color: 'black',
                                },
                            },
                        }}
                    >
                        <>
                            {!isAdminPage && (
                                <DashboardButton onClose={onClose} />
                            )}
                            <ContactButton />
                            {/*AI評価
                             <EvaluationButton /> */}
                            <AdminButton />
                            <FrontButton />
                            {!isAdminPage && <ToggleJobseekerViewButton />}
                        </>
                    </ButtonGroup>
                )}
                <Divider />

                <LogoutButton />
                <AppVersion />
            </VStack>
        </Box>
    )
}

HamburgerMenu.propTypes = {
    isDisplay: PropTypes.bool,
}

const Notification = ({ children, isNew = false }) => {
    return (
        <HStack w="full" pl={2} pr={3}>
            <chakra.div
                bg="blue.500"
                borderRadius="50%"
                height="8px"
                width="8px"
                visibility={isNew ? 'inherit' : 'hidden'}
            />
            {children}
        </HStack>
    )
}
Notification.propTypes = {
    children: PropTypes.node.isRequired,
    isNew: PropTypes.bool,
}

// TRYTDX-748
export const TaskChecker = () => {
    const notification = useSelector((state) =>
        notificationsSelectors.selectById(state, 'similar-facility-tasks')
    )
    return (
        <Notification isNew={notification?.read === false}>
            <Link to="/TRYTDX837">
                レコメンド評価（{notification?.data?.count ?? 0}）
            </Link>
        </Notification>
    )
}

// TODO: ここからアプリのバージョンアップをしたい
export const RevisionChecker = () => {
    return (
        <Notification>
            <Text>ダミーの通知（複数行確認用）</Text>
        </Notification>
    )
}

const DashboardButton = ({ onClose }) => {
    const navigate = useNavigate()
    return (
        <Button
            className="menu-button"
            onClick={() => {
                navigate('/dashboard')
                onClose()
            }}
            leftIcon={<HomeIcon />}
        >
            ダッシュボード(旧TOP)
        </Button>
    )
}

const ContactButton = () => {
    return (
        <Button className="menu-button">
            <NewContactButton backURL={location.href} isHeader />
        </Button>
    )
}

//AI評価
// const EvaluationButton = () => {
//     return (
//         <HighPerformersOnly>
//             <Button
//                 className="menu-button"
//                 variant="link"
//                 as={ExternalLink}
//                 href="/evaluations"
//                 leftIcon={<LearnIcon />}
//             >
//                 AI評価
//             </Button>
//         </HighPerformersOnly>
//     )
// }

const AdminButton = () => {
    const navigate = useNavigate()
    const isAdminPage = AdminPageChecker()
    const { isAdmin } = useAdmin()
    return (
        isAdmin &&
        !isAdminPage && (
            <Button
                className="menu-button"
                leftIcon={<AdminIcon />}
                onClick={() => navigate('/admin')}
            >
                管理画面
            </Button>
        )
    )
}

const AdminPageChecker = () => {
    return location.pathname.startsWith('/admin') ? true : false
}

const FrontButton = () => {
    const navigate = useNavigate()
    const isAdminPage = AdminPageChecker()
    return (
        isAdminPage && (
            <Button
                className="menu-button"
                leftIcon={<HomeIcon />}
                onClick={() => {
                    navigate('/')
                }}
            >
                ユーザー画面
            </Button>
        )
    )
}
