import { Stack } from '@chakra-ui/react'

import { AdminNotificationListContainer } from '../../notifications/components/NotificationList'
import { BusStationTable } from '../components/BusStationTable'
import { RailwayStationTable } from '../components/RailwayStationTable'

import { ContentLayout } from '@/components/Layout'
import { HighPerformers } from '@/features/high-performers'
import { Kpi } from '@/features/KPI/components/Kpi'

const AdminDashboard = () => {
    return (
        <ContentLayout title="ダッシュボード">
            <Stack w="1000px" spacing={5} mx="auto" mb="30px">
                <AdminNotificationListContainer />
                <Kpi />
                {/* AI評価
                <HighPerformers /> */}
                <RailwayStationTable />
                <BusStationTable />
            </Stack>
        </ContentLayout>
    )
}

export { AdminDashboard }
