import { useId } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'

import { useMapContext } from './MapContext'

/** @param {google.maps.MapOptions} [options] */
export const useMap = (options) => {
    /** @type {ReturnType<typeof useRef<HTMLDivElement>>} */
    const ref = useRef()
    /** @type {ReturnType<typeof useState<google.maps.Map>>} */
    const [map, setMap] = useState()
    useEffect(() => {
        if (ref.current && !map) {
            setMap(
                new window.google.maps.Map(
                    ref.current,
                    Object.assign(
                        {
                            center: {
                                lat: 0,
                                lng: 0,
                            },
                            zoom: 3,
                            // FIXME: 管理コンソールで指定するのが普通っぽい
                            disableDefaultUI: true,
                            zoomControl: true,
                            scaleControl: true,
                            controlSize: 25,
                            // streetViewControl: false,
                            // rotateControl: false,
                            // fullscreenControl: false,
                        },
                        options
                    )
                )
            )
        }
    }, [ref, map])
    return {
        ref,
        instance: map,
    }
}

/** @typedef {google.maps.marker.AdvancedMarkerElementOptions} MarkerOptions */

/**
 * @param {MarkerOptions & {
 *     onClick?: (e: google.maps.MapMouseEvent) => void
 * }} [options]
 */
export const useMarker = (options) => {
    /** @type {ReturnType<typeof useState<google.maps.marker.AdvancedMarkerElement>>} */
    const [marker, setMarker] = useState()
    const { instance: map } = useMapContext()
    useEffect(() => {
        if (!marker) {
            // console.log('create marker')
            const { onClick, ...opts } = options
            const instance =
                new window.google.maps.marker.AdvancedMarkerElement(opts)
            if (onClick) {
                instance.addListener('click', onClick)
            }
            setMarker(instance)
        }
        // remove marker from map on unmount
        return () => {
            // console.log('unmount', marker)
            if (marker) {
                // console.log('remove marker')
                marker.map = null
                window.google.maps.event.clearInstanceListeners(marker)
            }
        }
    }, [marker])
    useEffect(() => {
        if (marker && map) {
            marker.map = map
        }
    }, [marker, map])
    return {
        map,
        marker,
    }
}

/**
 * @param {{
 *     createNode: () => HTMLElement
 *     position: keyof typeof window.google.maps.ControlPosition
 * }} param0
 * @see {@link https://developers.google.com/maps/documentation/javascript/examples/control-custom}
 */
export const useMapController = ({ createNode, position }) => {
    const id = useId()
    const [node] = useState(() => {
        const node = createNode()
        if (!node.id) {
            node.id = id
        }
        return node
    })
    const { instance: map } = useMapContext()
    // console.log(node)
    useEffect(() => {
        const controls =
            map?.controls?.[window.google.maps.ControlPosition[position]]
        if (controls) {
            controls.push(node)
        }
        return () => {
            if (controls) {
                const index = controls
                    .getArray()
                    .findIndex((element) => element.id === node.id)
                controls.removeAt(index)
            }
        }
    }, [map, node, position])
    return node
}
