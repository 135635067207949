//契約の有無選択肢
export const contractChoices = [
    {
        label: '契約無',
        //契約無のオプション追加
        options: [
            {
                label: '紹介：未契約｜派遣：未契約',
                value: '紹介：未契約｜派遣：未契約',
            },
        ],
    },
    {
        label: '契約有',
        options: [
            {
                label: '紹介：法人一括｜派遣：施設単体',
                value: '紹介：法人一括｜派遣：施設単体',
            },
            {
                label: '紹介：法人一括｜派遣：法人一括',
                value: '紹介：法人一括｜派遣：法人一括',
            },
            {
                label: '紹介：法人一括｜派遣：未契約',
                value: '紹介：法人一括｜派遣：未契約',
            },
            {
                label: '紹介：施設単体｜派遣：施設単体',
                value: '紹介：施設単体｜派遣：施設単体',
            },
            {
                label: '紹介：施設単体｜派遣：法人一括',
                value: '紹介：施設単体｜派遣：法人一括',
            },
            {
                label: '紹介：施設単体｜派遣：未契約',
                value: '紹介：施設単体｜派遣：未契約',
            },
            {
                label: '紹介：未契約｜派遣：施設単体',
                value: '紹介：未契約｜派遣：施設単体',
            },
            {
                label: '紹介：未契約｜派遣：法人一括',
                value: '紹介：未契約｜派遣：法人一括',
            },
        ],
    },
]
