import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { useMapContext } from './MapContext'

/**
 * @see {@link https://developers.google.com/maps/documentation/javascript/infowindows?hl=ja}
 * @see {@link https://github.com/JustFly1984/react-google-maps-api/blob/master/packages/react-google-maps-api/src/components/drawing/InfoWindow.tsx}
 */
export const InfoWindow = ({
    isOpen = false,
    onClose,
    marker,
    children,
    ...options
}) => {
    /** @type {ReturnType<typeof useState<google.maps.InfoWindow>>} */
    const [infoWindow, setInfoWindow] = useState()
    const { instance: map } = useMapContext()
    const [content] = useState(() => document.createElement('div'))
    useEffect(() => {
        if (!infoWindow) {
            const instance = new window.google.maps.InfoWindow(
                Object.assign({ content }, options)
            )
            instance.addListener('closeclick', onClose)
            // 範囲外にあるときに開かれる、で地図範囲を調整するため
            instance.addListener('visible', () => {
                instance.focus()
            })
            setInfoWindow(instance)
        }
        return () => {
            if (infoWindow) {
                infoWindow.close()
                window.google.maps.event.clearInstanceListeners(infoWindow)
            }
        }
    }, [infoWindow])
    useEffect(() => {
        if (infoWindow) {
            if (!isOpen) {
                infoWindow.close()
            } else if (map && marker) {
                infoWindow.open({
                    map,
                    anchor: marker,
                })
            }
        }
    }, [infoWindow, marker, map, isOpen])
    return <>{createPortal(children, content)}</>
}
InfoWindow.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    marker: PropTypes.any,
}
