import {
    forwardRef,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    useModalContext,
} from '@chakra-ui/react'

/**
 * @param {import('@chakra-ui/react').ModalProps} props
 * @returns
 */
export const RoundedModal = (props) => {
    return <Modal scrollBehavior="inside" size="3xl" isCentered {...props} />
}

export const RoundedModalContent = forwardRef(
    /**
     * @param {import('@chakra-ui/react').ModalHeaderProps} props
     * @returns
     */
    (props, ref) => {
        return <ModalContent ref={ref} borderRadius="3xl" {...props} />
    }
)

export const RoundedModalHeader = forwardRef(
    /**
     * @param {import('@chakra-ui/react').ModalHeaderProps} props
     * @returns
     */
    (props, ref) => {
        return (
            <ModalHeader
                ref={ref}
                textAlign="center"
                px={12}
                py={8}
                {...props}
            />
        )
    }
)

export const RoundedModalBody = forwardRef(
    /**
     * @param {import('@chakra-ui/react').ModalBodyProps} props
     * @returns
     */
    (props, ref) => {
        return <ModalBody ref={ref} px={12} {...props} />
    }
)

export const RoundedModalFooter = forwardRef(
    /**
     * @param {import('@chakra-ui/react').ModalFooterProps} props
     * @returns
     */
    (props, ref) => {
        return (
            <ModalFooter
                ref={ref}
                justifyContent="center"
                gap={2}
                px={12}
                py={8}
                {...props}
            />
        )
    }
)

export { ModalOverlay }

export const ModalButton = forwardRef((props, ref) => {
    return <Button ref={ref} px="12" py="6" {...props} />
})

export const ModalCloseButton = forwardRef((props, ref) => {
    const { onClose } = useModalContext()
    return (
        <ModalButton ref={ref} onClick={onClose} {...props}>
            戻る
        </ModalButton>
    )
})
